/**
 * These constants get precompiled each time ./sq build is run and an updated buildConstants.service.js file
 * will be generated in the webserver/src/services folder. The source data for these constants can be found
 * in the variables.ini at the root of the crab project.
 */

/**
 * The current version string.
 */
export const SEEQ_VERSION = 'R58.8.12-v202405100801';
