/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 58.8.12-v202405100801
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/hybrid/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqReportTemplatesApiClass {
 
   constructor() {}

  /**
   * @summary Archive a report template
   * @param {string} id - ID of the report template to archive.
   */
  public archiveReportTemplate(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/report-templates/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Create a new report template
   */
  public createReportTemplate(
    body: models.ReportTemplateInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/report-templates`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ReportTemplateOutputV1>;
  }

  /**
   * @summary Get a report template item
   * @param {string} id - The Seeq ID for the report template
   */
  public getReportTemplate(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/report-templates/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ReportTemplateOutputV1>;
  }

  /**
   * @summary Get the thumbnail for a report template
   * @param {string} id - ID of the report template which you want the thumbnail of.
   */
  public getThumbnail(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/report-templates/thumbnail/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Update the given report template
   * @param {string} id - The Seeq ID for the report template
   */
  public updateReportTemplate(
    body: models.ReportTemplateInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/report-templates/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ReportTemplateOutputV1>;
  }

}


export const sqReportTemplatesApi = new sqReportTemplatesApiClass();
